export const LoginType = ({
    Manual: "0",
    Auto: "1"
})

export const ResponseStatus = ({
    ServerError: "error",
    OtherDeviceSignedIn: "1",
    DBCommunicationError: "2"
})

export const ErrMsgStr = ({
    ServerError: "サーバにアクセスできていません。",
    OtherDeviceSignedIn: "別の端末で乗車しています。\n再度ログインしてください。",
    DBCommunicationError: "DB通信失敗中です。",
    CarIdNull: "番号が読み取れませんでした。\nNFCタグが有効か確認してください。",
    CarIdHasChanged: "URLの車台番号が変更されています。\n再度ログインしてください。",
    UserNotExist: "ユーザーが存在しません。",
    UserEmpty: "ユーザ名は必須です。",
    PasswordEmpty: "パスワードは必須です。",
    IncorrectUserPassword: "ユーザー名またはパスワードが違います。",
    SignInFailureExceeded: "パスワード試行回数が超過しました。",
    GetCognitoUserFailure : "認証に問題が発生しました。\nシステム管理者に連絡してください。",
    SessionUpdateFalilure: "セッション更新に問題が発生しました。\n再度ログインしてください。"
})

export const CognitoSettings = ({
    ProjectRegion: "ap-northeast-1",
    CognitoRegion: "ap-northeast-1",
    UserPoolsId: process.env.REACT_APP_USER_POOL_ID,
    UserPoolsWebClientId: process.env.REACT_APP_CLIENT_ID,
    IdentityPoolId: ""
})