import './App.css';
import LoginSuccess from './components/LoginSuccess';
import * as Utils from './components/Utils';
import React from 'react';
import { LoginType, ResponseStatus, ErrMsgStr } from './components/ReferenceString'
import { isChrome, isEdge } from 'react-device-detect';

import { Auth } from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'


// 既存のcognitoの設定
Utils.AmplifyConfigure()

const App = () => {
  // パラメータを取得する処理
  const params = new URLSearchParams(window.location.search)
  const carId = params.get('carId')

  // 認証状態を取得
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  const [errorMsg, setErrorMsg] = React.useState(" ");
  const [loginType, setLoginType] = React.useState(LoginType.Auto);
  const [loginTimeRes, setLoginTimeRes] = React.useState();

  // 認証状態変更のハンドル
  React.useEffect(() => {
    Utils.LocalLog("useEffect","")
    Utils.LocalLog("authState", authState)
    Utils.LocalLog("user", user)
    if(authState === undefined) {
      Auth.currentAuthenticatedUser()
      .then(authData => {
        setAuthState(AuthState.SignedIn)
        setUser(authData)
      })
      .catch(err => {
        Utils.LocalLog(err)
      })
    }

    return onAuthUIStateChange((nextAuthState, authData) => {
      Utils.LocalLog("nextAuthState:", nextAuthState)
      Utils.LocalLog("authData", authData)

      // Email確認画面をスキップ
      if (nextAuthState === AuthState.VerifyContact) {
        nextAuthState = AuthState.SignedIn
      }
      setAuthState(nextAuthState);
      setUser(authData);

      if (nextAuthState === AuthState.SignIn) {
        // スタイル設定
        Utils.ErrorStyle()
      }
    })
  }, []);

  // 認証成功の場合、ログインボタンハンドル
  async function handleSubmit(event) {
    event.preventDefault()

    Utils.LocalLog("手動ログイン","")

    // 車台番号なしチェック
    if (!carId) {
      setErrorMsg(ErrMsgStr.CarIdNull)
      return;
    }

    // carId をlocalStorageに保存する
    localStorage.setItem('carId', carId)

    // 手動ログインタイプ設定
    setLoginType(LoginType.Manual)

    let userId = ""
    let password = ""
    // ユーザ名・パスワード取得 
    if (isChrome || isEdge) {
      userId = event.path[0].getElementsByTagName("input")[1].value;
      password = event.path[0].getElementsByTagName("input")[2].value;
    } else {
      let path = document.getElementsByTagName("amplify-sign-in")[0].shadowRoot
      userId = path.childNodes[1].getElementsByTagName('input')[1].value;
      password = path.childNodes[1].getElementsByTagName('input')[2].value;
    }

    let signedInFlag = false
    let deviceKey = ""
    let jwt = ""
    let loginTime = ""
    let authTime = 0
    let expTime = 0

    // Amplify 認証ハンドル
    await Auth.signIn(userId, password)
      .then(function (response) {
        // Amplify 認証成功
        Utils.LocalLog("Amplify 認証成功: ", response)

        // Device KeyやUUIDを取得
        let accessToken = response.signInUserSession.accessToken
        deviceKey = accessToken.payload.device_key
        authTime = accessToken.payload.auth_time
        expTime = accessToken.payload.exp
        let idToken = response.signInUserSession.idToken
        jwt = idToken.jwtToken;

        // 現在時刻取得
        let currentDate = new Date();
        loginTime = currentDate.getFullYear() + "-" + currentDate.getMonth() + "-" + currentDate.getDay() + " " +
          currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds();

        // 処理終わるフラッグを設定
        signedInFlag = true
      })
      .catch(function (error) {
        // Amplify 認証失敗
        Utils.LocalLog("Amplify 認証失敗: ", error)

        let errorMsg = ""
        // 認証確認（失敗）
        switch (error.message) {
          case "Username cannot be empty":
            errorMsg = ErrMsgStr.UserEmpty;
            break;

          case "Custom auth lambda trigger is not configured for the user pool.":
            errorMsg = ErrMsgStr.PasswordEmpty;
            break;

          case "User does not exist.":
            errorMsg = ErrMsgStr.UserNotExist;
            break;

          case "Incorrect username or password.":
            errorMsg = ErrMsgStr.IncorrectUserPassword;
            break;

          case "Password attempts exceeded":
            errorMsg = ErrMsgStr.SignInFailureExceeded;
            break;

          default:
        }

        setErrorMsg(errorMsg);
      })


    if (signedInFlag === true) {
      // DB通信
      await Utils.loginApi(userId, deviceKey, carId, loginTime, LoginType.Manual, jwt)
        .then((returnValue) => {
          
          Utils.LocalLog("DB通信の戻り値: ", returnValue)

          setLoginTimeRes(returnValue.login_time)

          if (returnValue === ResponseStatus.ServerError) {
            setErrorMsg(ErrMsgStr.ServerError)
            Auth.signOut()
            // return;
          } else if (returnValue.status === ResponseStatus.DBCommunicationError) {
            setErrorMsg(ErrMsgStr.DBCommunicationError)
            Auth.signOut()
            // return;
          } else {
            // ログイン成功
            setErrorMsg(" ")
            // set logIn time
            localStorage.setItem('authTime', authTime)
            localStorage.setItem('expTime', expTime)

          }
        })

    }

  }


  Utils.LocalLog("authState", authState) 
  Utils.LocalLog("user", user) 
  Utils.LocalLog("errorMsg", errorMsg) 
  // UI表示
  return authState === AuthState.SignedIn && user && errorMsg === " " ? (   // ログイン成功
    <div className="App">
      <header className="App-header">
        <LoginSuccess type={loginType}
          setErrorMsg={setErrorMsg}
          loginTimeRes={loginTimeRes}
          setLoginTimeRes={setLoginTimeRes} />
      </header>
    </div>
  ) : (               // ログイン失敗と初期起動
    <div className="App">
      <header className="App-header">
        <AmplifyAuthenticator hideToast>
          <AmplifySignIn
            headerText="乗車確認"
            slot="sign-in"
            formFields={[
              { type: "hidden", label: "NFCタグ番号：" + carId },
              { type: "username", label: "ユーザー名", placeholder: "" },
              { type: "password", label: "パスワード", placeholder: "", hint: errorMsg },
            ]}
            hideSignUp="true"
            submitButtonText="乗車する"
            handleSubmit={handleSubmit}
          ></AmplifySignIn>
        </AmplifyAuthenticator>
      </header>
    </div>
  );
}

export default App;