import React, { Component } from 'react';
import { Auth } from "aws-amplify";

import * as Utils from './Utils';
import { LoginType, ResponseStatus, ErrMsgStr, CognitoSettings } from './ReferenceString'
import "./LoginSuccess.css"

class LoginSuccess extends Component {

    constructor(props) {
        super(props);
        this.props = props
    }

    async componentDidMount() {
        // 手動か自動か判断する(loginStyle)
        let loginType = this.props.type

        // 自動ログインの場合
        if (loginType === LoginType.Auto) {
            Utils.LocalLog("自動ログイン", "")

            // DB 通信
            let params = new URLSearchParams(window.location.search)
            let carId = params.get('carId')

            // 車台番号なしかチェック
            if (!carId) {
                this.props.setErrorMsg(ErrMsgStr.CarIdNull)
                Auth.signOut()
                return;
            }

            // carId変更されたかチェック
            let localCarId = localStorage.getItem('carId');
            if (localCarId !== carId) {
                this.props.setErrorMsg(ErrMsgStr.CarIdHasChanged)
                Auth.signOut()
                return;
            }

            // ユーザ存在なしチェック
            let err = ""
            await Auth.fetchDevices()
                .then(function () {
                    Utils.LocalLog("fetchDevices 成功", "")
                })
                .catch(function (error) {
                    if (error.code === "NotAuthorizedException") {
                        err = ErrMsgStr.UserNotExist;
                    }
                })

            if (err !== "") {
                this.props.setErrorMsg(err)
                Auth.signOut()
                return;
            }

            // ログインSesssion のタイムを更新する
            const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
            const poolData = {
                UserPoolId: CognitoSettings.UserPoolsId,
                ClientId: CognitoSettings.UserPoolsWebClientId,
            };
            const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
            const cognitoUser = userPool.getCurrentUser();
            Utils.LocalLog("cognitoUser: ", cognitoUser)

            if (cognitoUser === null) {
                // 現在接続しているユーザーをCognitoから取得失敗
                this.props.setErrorMsg(ErrMsgStr.GetCognitoUserFailure)
                Auth.signOut()
                return;
            }

            // 現在ログイン中のトークンを取得
            //（CognitoのAccess token expirationタイムを基づいて、定期的にアクセストークンが更新されるため 、
            // refreshSessionを強制実施する必要がある）
            Auth.currentSession().then(res => {
                const refresh_token = res.getRefreshToken();
                Utils.LocalLog("Auth.currentSession() res: ", res)
                Utils.LocalLog("refresh_token: ", refresh_token)

                // すぐアクセストークンを更新する（現在時刻と新規の有効期限を取得するため）
                cognitoUser.refreshSession(refresh_token, (refErr, refSession) => {
                    if (refErr) {
                        Utils.LocalLog("refErr: ", refErr)
                        Utils.LocalLog("refSession: ", refSession)
                        // Sessionを更新することが失敗
                        this.props.setErrorMsg(ErrMsgStr.SessionUpdateFalilure)
                        Auth.signOut()
                    }
                    else {
                        Utils.LocalLog("refSession: ", refSession)

                        // expTime out の場合、ログアウトする
                        let tokenTimeNow = refSession.getAccessToken().payload.iat
                        Utils.LocalLog("Token Time Now:", tokenTimeNow)

                        let localExpTime = parseInt(localStorage.getItem("expTime"))
                        Utils.LocalLog("Token Expire Time:", localExpTime)

                        if (tokenTimeNow > localExpTime) {
                            Utils.LocalLog("Token Time Out:", "")

                            Auth.signOut();
                            this.props.setErrorMsg("　")
                            return;
                        } else {
                            // save localStorage: localExpTime = 新しexp Time
                            let newExpTime = refSession.getAccessToken().payload.exp
                            localStorage.setItem('expTime', newExpTime)

                            Utils.LocalLog("New Token Expire Time:", newExpTime)
                        }

                        // Device KeyやUUIDを取得
                        let accessToken = res.getAccessToken()
                        let deviceKey = accessToken.payload.device_key
                        let idToken = res.getIdToken()
                        let jwt = idToken.getJwtToken()
                        let userId = idToken.payload["cognito:username"]

                        // 現在時刻を取得
                        let currentDate = new Date();
                        let loginTime = currentDate.getFullYear() + "-" + currentDate.getMonth() + "-" + currentDate.getDay() + " " +
                            currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds();
                        Utils.loginApi(userId, deviceKey, carId, loginTime, LoginType.Auto, jwt)
                            .then((returnValue) => {
                                Utils.LocalLog("DB通信の戻り値: ", returnValue)

                                if (returnValue === ResponseStatus.ServerError) {
                                    this.props.setErrorMsg(ErrMsgStr.ServerError)
                                    Auth.signOut()
                                } else if (returnValue.status === ResponseStatus.OtherDeviceSignedIn) {
                                    // 端末違い
                                    this.props.setErrorMsg(ErrMsgStr.OtherDeviceSignedIn)
                                    Auth.signOut()
                                } else if (returnValue.status === ResponseStatus.DBCommunicationError) {
                                    this.props.setErrorMsg(ErrMsgStr.DBCommunicationError)
                                    Auth.signOut()
                                } else {
                                    // 自動ログイン成功
                                    this.props.setLoginTimeRes(returnValue.login_time)
                                }
                            })

                    }
                });

            })

        } else {
            // 手動ログイン
        }
    }

    // UI設定
    render() {
        return (
            <SignedIn time={this.props.loginTimeRes} />
        )
    }
}

export default LoginSuccess;


// 乗車確認完了UI
const SignedIn = ({ time }) => {
    return (
        <div>
            <form className="form-style">
                <h3 className="header-style">乗車確認</h3>
                <label className="label-top">
                    {
                        time ? (
                            "乗車確認完了しました"
                        ) : (
                            "通信中..."
                        )
                    }
                </label>
                <br />
                <label className="label-time">
                    {
                        time || ""
                    }
                </label>
            </form>
        </div>
    )
}